import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

/**
 * Validates phone number
 * @param phoneNumber
 * @param countryCode
 * @returns boolean
 */
export const validatePhoneNumber = (
  phoneNumber: string,
  countryCode: string
) => {
  const phone = parsePhoneNumber(
    phoneNumber,
    countryCode.toUpperCase() as CountryCode
  );

  return phone?.isValid();
};
