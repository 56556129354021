import * as React from 'react';
import { useContext } from 'react';
import { QrReader } from 'react-qr-reader';
import { ToastContext } from '../';
import { SearchBarProps } from './SearchBar';

const QrCodeReader: React.FC<SearchBarProps> = ({ searchHandler }) => {
  const { setToast } = useContext(ToastContext);

  return (
    <>
      <h4>Read “QR Code</h4>
      <p>
        QR Code” to find the customer using their personal QR code. Show the
        customer’s “QR Code” to the camera.
      </p>
      <QrReader
        onResult={(result: any) => {
          if (!!result) {
            if (!result.text.includes('http')) {
              searchHandler(window.atob(result.text));
            } else {
              setToast({
                text: 'This QRCode is not valid!'
              });
            }
          }
        }}
        containerStyle={{ maxHeight: '500px', overflow: 'hidden' }}
        videoStyle={{ width: '100%', maxHeight: '450px' }}
        constraints={{ facingMode: 'environment' }}
      />
    </>
  );
};

export default QrCodeReader;
