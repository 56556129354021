import { useState, useCallback, useMemo } from 'react';
import {getUsers, getUsersPager} from '../api';

export type User = {
  created: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  gender: string;
  handles: {
    [key: string]: Array<any>
  };
  id: string;
  lastName: string;
  modified: string;
  sourceApp: string;
}

export type Users = {
  [key: string]: User
}

export const useUsersController = () => {
  const [usersPager, setUsersPager] = useState(() => getUsersPager());
  const [users, setUsers] = useState<Users>({});

  const fetchUsersByID = useCallback(async (ids: Array<string>) => {
    const usersToFetch: string[] = [];
    for (const id of ids) {
      if (!(id in users))
        usersToFetch.push(id)
    }
    if (usersToFetch.length < 1)
      return;
    const usersPage = (await getUsers(true, null, usersToFetch)).users;
    const newUserObjects = {...users,...Object.fromEntries(usersPage.map(user => [user.id, user]))}
    setUsers(newUserObjects)
    return newUserObjects
  }, [users]) 

  const fetchNextPage = useCallback(async () => {
    let usersPage;

    if (usersPager) {
      usersPage = await usersPager();
    }
  
    if (!usersPage)
      return null;
    
      setUsers((oldUsers) => ({...oldUsers,...Object.fromEntries(usersPage.map(user => [user.id, user]))}))
  
    return usersPage;
  }, [usersPager])

  const resetPager = useCallback(async () =>{
    setUsersPager(() => getUsersPager());
  }, [])

  const usersController = useMemo(() => ({ users, fetchNextPage, resetPager, fetchUsersByID }), [fetchNextPage, fetchUsersByID, resetPager, users])

  return usersController
}