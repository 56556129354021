import * as React from 'react';
import { useEffect, useState } from 'react';

export const useGenerateDataURL = (
  imgURL: string,
  shouldProcess: boolean,
  deps: React.DependencyList = []
): string => {
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    if (imgURL && shouldProcess) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const base_image = new Image();
      base_image.crossOrigin = 'anonymous';
      base_image.src = imgURL;
      base_image.onload = () => {
        canvas.width = base_image.width;
        canvas.height = base_image.height;
        ctx.drawImage(base_image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        setDataUrl(dataURL.replace('image/png', 'image/octet-stream'));
      };
    }
  }, [shouldProcess, imgURL, ...deps]);

  return dataUrl;
};
