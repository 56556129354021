import * as React from 'react';
import { Container, Card } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const WrapComponent = () => (
  <Container>
    <Card className="p-3" style={{ maxWidth: '950px', margin: '0 auto 20px' }}>
      <Outlet />
    </Card>
  </Container>
);

export default WrapComponent;
