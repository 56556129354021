import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  toastWrap: {
    position: 'fixed',
    zIndex: 1100,
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,.69)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  toast: {
    position: 'relative',
    padding: '30px',
    borderRadius: '30px',
    background: '#fff',
    border: '3px solid',
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
    maxWidth: '500px',
    '& h5': {
      margin: 0
    }
  },
  cross: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '15px',
    right: '15px',
    '&:before, &:after': {
      content: '""',
      display: 'inline-block',
      background: '#000',
      width: '100%',
      height: '3px',
      position: 'absolute',
      top: '50%',
      left: '0',
      cursor: 'pointer'
    },
    '&:before': {
      transform: 'rotate(45deg)'
    },
    '&:after': {
      transform: 'rotate(-45deg)'
    }
  }
});

export interface ToastProps {
  text: string;
  setToast: (param: any) => void;
}

const Toast: React.FC<ToastProps> = ({ text, setToast }) => {
  const style = useStyles();

  return (
    <div className={style.toastWrap}>
      <div className={style.toast} style={{ borderColor: '#0d6efd' }}>
        <div className={style.cross} onClick={() => setToast(null)} />
        <h5 style={{ color: '#0d6efd' }}>{text}</h5>
      </div>
    </div>
  );
};

export default Toast;
