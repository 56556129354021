import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { AppContext } from '..';

interface AboutUsProps {
  show: boolean;
  handleClose: () => void;
}

export const AboutUs: React.FC<AboutUsProps> = ({ show, handleClose }) => {
  const { id } = useContext(AppContext);
  const [email, setEmail] = useState(null);

  const getEmail = React.useCallback(async () => {
    const user = await Auth.currentUserInfo();
    setEmail(user.attributes.email);
  }, []);

  useEffect(() => {
    getEmail();
  }, []);

  return (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>About</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5 className="fst-italic fw-light mb-4">Information about your session</h5>
      <div className="d-flex">
        <h5>email: <span className="fw-light">{email}</span></h5>
      </div>
      <div className="d-flex">
        <h5>app-id: <span className="fw-light">{id}</span></h5>
      </div>
      <div className="d-flex">
        <h5>webpage version: <span className="webpage-version fw-light"/></h5>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  )
}